<template>
  <div class="card">
    <img :src="card.pictureUrl" :alt="card.name" class="card-image">
    <div class="card-content">
      <h3>{{ card.name }}</h3>
      <p>{{ card.club }}</p>
      <p>{{ card.averageScore }}</p>
      <p>{{ card.position }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    card: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.card-image {
  width: 100%; /* Set the width to 100% to occupy the entire space */
  height: auto;
}

/* Additional styles for the card */
.card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  width: 200px;
  display: inline-block; /* Display the cards in a line */
  vertical-align: top; /* Align the cards to the top of the line */
}

.card-content {
  text-align: center;
}
</style>
