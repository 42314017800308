// ApiService.js

const API_URL = 'https://lseousa9x4.execute-api.eu-central-1.amazonaws.com/prod';
const API_HEADERS = {
  'Content-Type': 'application/json'
};

export default {
  fetchGallery(manager) {
    manager = manager.toLowerCase();

    const requestOptions = {
      method: 'POST',
      headers: API_HEADERS,
      body: JSON.stringify({ manager }),
      redirect: 'follow'
    };

    return fetch(API_URL, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('API request failed');
        }
        return response.json();
      })
      .then(result => {
        return {
          statusCode: result.statusCode,
          body: result.body
        };
      })
      .catch(error => {
        console.log('APIService/API error:', error);
        throw error;
      });
  }
};
