<template>
  <div id="app">
  

    <div class="bg-gray-100 p-2 flex flex-col items-center sm:flex-row sm:justify-between">
      <div class="flex items-center">
        <img class="logo ml-2" alt="logo" src="./assets/logo.png">
        <div class="ml-2">
          <h2 class="text-2xl font-bold">Sorare Spotlight</h2>
          <p class="text-gray-700 text-sm">A website dedicated to showcasing your Sorare cards and providing insights by playing around with them</p>
        </div>
      </div>
      <form class="flex items-center mt-2 sm:mt-0">
        <label class="block sm:hidden mr-2">Managername</label>
        <input type="text" v-model="manager" class="w-full border rounded py-2 px-3 mr-2">
        <button
          type="submit"
          @click="callAPI"
          :disabled="loading"
          class="py-2 px-4 rounded bg-blue-500 text-white"
        >
          <template v-if="loading">
            <i class="fas fa-spinner fa-spin"></i>
          </template>
          <template v-else>
            Check
          </template>
        </button>
      </form>
    </div>
    <div 
      v-if="gallery.length > 0" 
      class="mx-auto p-8">
      <div class="container mx-auto p-8 flex flex-row">
        <div class="text-sm mr-8">Gesamtanzahl Karten: {{ gallery.length }}</div>
        <div v-if="gallery.length > limitedCardCount" class="text-sm mr-8">Limited Karten: {{ limitedCardCount }}</div>
        <div v-if="gallery.length > rareCardCount" class="text-sm">Rare Karten: {{ rareCardCount }}</div>
      </div>

      <Card
        v-for="card in gallery"
        :key="card.id"
        :card="card"
        class="mt-4 border rounded p-4"
      />

    </div>
</div>

  
</template>


<script>

import Card from './components/Card.vue';
import ApiService from './services/ApiService';


export default {
  name: 'Form',
  data() {
    return {
      manager: '',
      gallery:{},
      loading: false,
    }
  },
  components: {
    Card
  },
  methods: {
    callAPI() {
      this.loading = true;
      ApiService.fetchGallery(this.manager)
          .then(response => {
            if (response.statusCode === 200) {
              this.gallery = JSON.parse(response.body);
            } else {
              console.log('APP/ not 200 error', response);
              alert("Error: " + response.body);
            }
          })
          .catch(error => console.log('APP/ API error', error))
          .finally(() => {
            this.loading = false;
          });
    }
  },
  computed: {
    limitedCardCount() {
      return this.gallery.filter(card => card.rarity === 'limited').length;
    },
    rareCardCount() {
      return this.gallery.filter(card => card.rarity === 'rare').length;
    },
    superrareCardCount() {
      return this.gallery.filter(card => card.rarity === 'super rare').length;
    }
  }
}
</script>



<style src="./assets/Form.css" scoped></style>
